const logo = document.querySelector('#logo');
const path = '/wp-content/themes/sitalav2/dist/img/';

const checkActiveSlide = () => {
  const logoIsWhite =
    document.querySelector('body').getAttribute('data-logo-white') === 'true';

  logoIsWhite
    ? (logo.src = `${path}logo-sitala-bis.svg`)
    : (logo.src = `${path}logo-sitala.svg`);
};

checkActiveSlide();

window.addEventListener('scroll', () => {
  const st = window.pageYOffset || document.documentElement.scrollTop;
  st > 20 ? (logo.src = `${path}logo-sitala.svg`) : checkActiveSlide();
});
